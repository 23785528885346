import { Injectable } from '@angular/core';
import { Report, ReportTemplate, ReportVersion } from 'src/api-client/report-api.generated';
import { ReportApiService } from 'src/app/api-client/report-api/report-api-service';
import { TemplateApiService } from 'src/app/api-client/report-api/template-api-service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { IndicatorsPageStateService } from '../indicators/indicators-page-state.service';

@Injectable({
  providedIn: 'root',
})
export class ReportStateService {
  reportFormDialogOpen: boolean = false;
  reportVersion?: ReportVersion;

  isLoadingReports = false;
  isLoadingTemplates = false;
  reports: Report[] = [];
  templates: ReportTemplate[] = [];
  convertingReportForId?: string;
  constructor(
    private reportApiService: ReportApiService,
    private templateApiService: TemplateApiService,
    private IndicatorsPageStateService: IndicatorsPageStateService,
    private notificationService: NotificationService
  ) {}

  handleCreate(reportVersion: ReportVersion) {
    this.reportVersion = reportVersion;
    this.reportFormDialogOpen = true;
  }

  handleCloseCreateDialog() {
    this.reportVersion = undefined;
    this.reportFormDialogOpen = false;
  }

  loadReports() {
    this.isLoadingReports = true;
    this.reportApiService.all().subscribe({
      next: result => {
        this.reports = result.result || [];
        this.isLoadingReports = false;
      },
      error: error => {
        this.isLoadingReports = false;
      },
      complete: () => {
        this.isLoadingReports = false;
      },
    });
  }

  removeReport(reportId: string) {
    this.reports = this.reports.filter(r => r.id !== reportId);
    this.reportApiService.removeById(reportId).subscribe(result => {
      console.log('Removed: ' + reportId);
    });
  }

  removeTemplate(templateId: string) {
    this.templates = this.templates.filter(r => r.id !== templateId);
    this.templateApiService.removeTemplate(templateId).subscribe(result => {
      console.log('Removed: ' + templateId);
    });
  }

  loadTemplates() {
    this.isLoadingTemplates = true;
    this.templateApiService.getAllTemplates(false).subscribe(
      result => {
        this.templates = result.result || [];
        this.isLoadingTemplates = false;
      },
      error => {
        this.isLoadingTemplates = false;
      },
      () => {
        this.isLoadingTemplates = false;
      }
    );
  }
}
