import { Injectable } from '@angular/core';
import { Component, IndicatorDataValueType } from 'src/api-client/report-api.generated';
import { TopicItemApiService } from 'src/app/api-client/report-api/topic-item-api-service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { IIndicatorValueConnection, IIndicatorValueRow } from './indicator-values/indicator-values.component';
import { SubstrateDataUi } from './substrate-data/substrate-data.component';

export interface TopicItemContentUi {
  id: string;
  name: string;
  visible: boolean;
  description?: string;
  referenceStandards?: string;
  calculationDate?: Date;
  type?: string;
  predefinedIndicatorId?: string;
  dataValues?: DataValuesUi[];
  substrateData?: SubstrateDataUi;
  components?: Component[];
}

export interface DataValuesUi {
  indicatorId?: string;
  currentValue: DataValueUi;
  metadata: DataValueMetaDataUi;
  previousValue: DataValueUi;
  targetValue: DataValueUi;
  isCurrentValueConnected: boolean;
  isPreviousValueConnected: boolean;
  substrateData?: SubstrateDataUi;
}

interface DataSingleValueMetaDataUi {
  label?: string;
  type?: string;
  unit?: string;
  unitDescription?: string;
  groupLabel?: string;
  isVisibleInReport?: boolean;
}

interface DataValueMetaDataUi extends DataSingleValueMetaDataUi {
  tableColumnsMetadata?: DataSingleValueMetaDataUi[];
}

interface DataSingleValueUi {
  type?: string;
  value?: string | number;
  unitDetails?: string;
  errorMessage?: string;
}

export interface DataValueUi extends DataSingleValueUi {
  tableRows?: DataTableValueRowUi[];
}

export interface DataTableValueRowUi {
  values?: DataSingleValueUi[];
}

@Injectable()
export class TopicItemDetailsStateService {
  lastUpdated?: Date;
  numericalValues?: DataValuesUi[];
  tableValues?: DataValuesUi[];
  substrateData?: SubstrateDataUi;
  visibile = false;
  calculateIsHovered: boolean = false;

  constructor(private topicItemApiService: TopicItemApiService, private notificationService: NotificationService) {}

  setIndicatorValues(values: DataValuesUi[]) {
    this.tableValues = values.filter(value => value.metadata.type === IndicatorDataValueType.Table);
    this.numericalValues = values.filter(value => value.metadata.type !== IndicatorDataValueType.Table);
  }

  handleOnValueSubmit(indicatorValue: IIndicatorValueRow, topicItemId: string, values: DataValuesUi[]) {
    throw new Error('handleOnValueSubmit Method not implemented.');
  }

  handleOnSetDataCollectorConnection(
    topicItemId: string,
    values: DataValuesUi[],
    indicatorValueConnection: IIndicatorValueConnection
  ) {
    throw new Error('handleOnSetDataCollectorConnection Method not implemented.');
  }

  initCalculationData(lastUpdated: Date | undefined, substrateData: SubstrateDataUi | undefined) {
    this.lastUpdated = lastUpdated;
    this.substrateData = substrateData;
  }

  setCalculationIsHovered(hover: boolean) {
    this.calculateIsHovered = hover;
  }

  handleCalculate(topicItemId: string, values: DataValuesUi[]) {
    throw new Error('handleCalculate Method not implemented.');
  }

  async handleCalculateNewIndicator(topicItemId: string) {
    var response = await this.topicItemApiService.refreshTopicItemNewIndicatorValues(topicItemId);
    console.log(response);
  }
}
