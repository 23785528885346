import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IndicatorCategory, Permission, UserRole } from 'src/api-client/report-api.generated';
import { IndicatorApiService } from 'src/app/api-client/report-api/indicator-api-service';
import { IndicatorDefinitionApiService } from 'src/app/api-client/report-api/indicator-definition-api-service';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import { AppInfoService } from 'src/app/core/app-info.service';

@Injectable({
  providedIn: 'root',
})
export class IndicatorsPageStateService {
  readonly categoryEnum = IndicatorCategory;

  private userInfoSub!: Subscription;

  libraryIndicatorCount?: number;
  ourIndicatorsCount?: number;
  categoryCounts: { [key in IndicatorCategory]?: number } = {};
  recentlyAdded: { [key in IndicatorCategory]?: boolean } = {};

  hasIndicatorLibraryAccess: boolean = false;

  get useNewMainNavigation() {
    return this.appConfig.NewNavbarEnabled;
  }

  constructor(
    private appConfig: AppConfigurationService,
    private indicatorDefinitionsApiService: IndicatorDefinitionApiService,
    private indicatorApiService: IndicatorApiService,
    private route: ActivatedRoute,
    private appInfo: AppInfoService
  ) {
    this.init();
  }

  async init() {
    const activeChildPath = this.route.snapshot.firstChild?.routeConfig?.path;
    if (activeChildPath !== 'library') this.getLibraryIndicatorCount();
    if (activeChildPath !== 'our-indicators') this.getOurIndicatorsCount();
    Object.values(IndicatorCategory).forEach(category => {
      this.recentlyAdded[category] = false;
    });
    this.userInfoSub = this.appInfo.userInfo$.subscribe(userInfo => {
      this.hasIndicatorLibraryAccess = userInfo?.role === UserRole.Admin || userInfo?.role === UserRole.SuperAdmin;
    });
  }

  async getLibraryIndicatorCount() {
    const response = await this.indicatorDefinitionsApiService.getAllNames(true);
    this.setLibraryIndicatorCount(response.result.length);
  }

  async getOurIndicatorsCount() {
    const response = await this.indicatorApiService.getAllIndicators();
    this.setOurIndicatorsCount(response.result.length);
    this.categoryCounts = Object.fromEntries(Object.values(IndicatorCategory).map(category => [category, 0]));
    response.result.map(indicator => {
      this.categoryCounts[indicator.category] = (this.categoryCounts[indicator.category] || 0) + 1;
    });
  }

  getAllIndicatorsCount() {
    this.getLibraryIndicatorCount();
    this.getOurIndicatorsCount();
  }

  setLibraryIndicatorCount(count: number) {
    this.libraryIndicatorCount = count;
  }

  setOurIndicatorsCount(count: number) {
    this.ourIndicatorsCount = count;
  }

  setRecentlyAddedFlag(category: IndicatorCategory) {
    this.recentlyAdded[category] = true;

    setTimeout(() => {
      this.clearRecentlyAddedFlag(category);
    }, 5000);
  }

  clearRecentlyAddedFlag(category: IndicatorCategory) {
    this.recentlyAdded[category] = false;
  }

  ngOnDestroy() {
    this.userInfoSub.unsubscribe();
  }
}
