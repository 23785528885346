import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import { ReportStateService } from './report-state.service';
import { Permission, ReportStatus, ReportVersion } from 'src/api-client/report-api.generated';
import { AppInfoService } from 'src/app/core/app-info.service';
import { IOption, reportStatusOptions } from 'src/app/static-data/options';
import { ITabItem } from 'src/app/shared/ui/tabs/tabs.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'esg-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportPageComponent implements OnInit {
  reportVersionEnum = ReportVersion;
  private userInfoSub!: Subscription;
  private organizationContextSub!: Subscription;
  removeReportId?: string;
  removeTemplateId?: string;
  selectedTemplateId?: string;
  removeMessage: string = '';

  hasCreateReportPermission = false;
  hasRemoveReportPermission = false;

  tabs: ITabItem[] = [{ value: 'reports', label: 'Your reports' }];
  activeTab: string = this.tabs[0].value;

  constructor(
    private appConfig: AppConfigurationService,
    private appInfoState: AppInfoService,
    public state: ReportStateService,
    private router: Router
  ) {}

  getStatusColor(status?: ReportStatus): IOption {
    var statusOption = reportStatusOptions.find(s => s.value === status);
    if (!statusOption) {
      throw Error('Report status not mapped.');
    }
    return statusOption;
  }

  ngOnInit(): void {
    this.userInfoSub = this.appInfoState.userInfo$.subscribe(() => this.init());
    this.organizationContextSub = this.appInfoState.organizationContext$.subscribe(() => this.init());
  }

  init() {
    this.hasCreateReportPermission = this.appInfoState.hasPermission(Permission.Report_Create);
    this.hasRemoveReportPermission = this.appInfoState.hasPermission(Permission.Report_Delete);

    this.state.loadReports();

    if (this.appInfoState.hasPermission(Permission.Template_Read)) {
      !this.tabs.find(val => val.value === 'templates') &&
        this.tabs.push({ value: 'templates', label: 'Custom  templates' });
      this.state.loadTemplates();
    }
  }

  handleSelectReport(reportId: string) {
    this.router.navigate(['/report', reportId]);
  }

  getCoverPhotoUrl(blobName?: string) {
    return blobName ? `${this.appConfig.ReportApiBaseUrl}/report/cover-photo/${blobName}` : undefined;
  }

  handleRemoveSubmit() {
    if (this.removeReportId) {
      this.state.removeReport(this.removeReportId);
      this.removeReportId = undefined;
    } else if (this.removeTemplateId) {
      this.state.removeTemplate(this.removeTemplateId);
      this.removeTemplateId = undefined;
    }
  }
  handleRemoveCancel() {
    this.removeReportId = undefined;
    this.removeTemplateId = undefined;
  }

  handleRemoveReport(reportId: string) {
    this.removeMessage = 'Once you delete this report, nobody can access it and there is no turning back.';
    this.removeReportId = reportId;
  }

  handleRemoveTemplate(templateId: string) {
    this.removeMessage = 'Once you delete this template, nobody can access it and there is no turning back.';
    this.removeTemplateId = templateId;
  }

  handleSelectTemplate(templateId: string) {
    this.selectedTemplateId = templateId;
  }

  handleOnEditTemplate() {
    this.state.loadTemplates();
    this.selectedTemplateId = undefined;
  }

  handleCloseTemplateDialog() {
    this.selectedTemplateId = undefined;
  }

  handleOnTabClick(value: string) {
    this.activeTab = value;
  }

  ngOnDestroy() {
    this.userInfoSub.unsubscribe();
    this.organizationContextSub.unsubscribe();
  }
}
