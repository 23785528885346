<span class="title">{{ calculatedData.metadata.label }}</span>
<table *ngIf="calculatedTable" class="calculation-table">
  <thead>
    <tr *ngFor="let row of calculatedTable.tHead">
      <ng-container *ngFor="let cell of row.items; let index = index">
        <th
          [attr.rowspan]="cell.rowSpan"
          [attr.colspan]="cell.colSpan"
          [class.number]="cell.isNumber"
          [style.background]="cell.backgroundColor ? cell.backgroundColor : 'unset'">
          {{ cell.content }}
        </th>
      </ng-container>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of calculatedTable.tBody">
      <ng-container *ngFor="let cell of row.items; let index = index">
        <td
          [attr.rowspan]="cell.rowSpan"
          [attr.colspan]="cell.colSpan"
          [class.number]="cell.isNumber"
          [style.background]="cell.backgroundColor ? cell.backgroundColor : 'unset'">
          {{ cell.isNumber ? getFormattedNumbers(cell.content || '', 0, 0) : cell.content || '' }}
          {{ cell.unit || '' }}
        </td>
      </ng-container>
    </tr>
  </tbody>
  <tr *ngIf="references">
    <td class="reference" [attr.colspan]="calculatedData.metadata.tableColumnsMetadata?.length">
      <span class="label">Reference:&nbsp;</span>
      <span>{{ references }}</span>
    </td>
  </tr>
</table>
