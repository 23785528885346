import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { IconModule } from './icons/icon.module';
import { TextboxComponent } from './textbox/textbox.component';
import { TextboxFormComponent } from './textbox/textbox-form.component';
import { ButtonComponent } from './button/button.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DialogComponent } from './dialog/dialog.component';
import { SideDialogComponent } from './side-dialog/side-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { UploadPictureComponent } from './upload-picture/upload-picture.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { OnClickOutsideComponent } from './on-click-outside/on-click-outside.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TextareaComponent } from './textarea/textarea.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { TextEditorComponent } from './text-editor/text-editor.component';
import { TextEditorToolbarComponent } from './text-editor/text-editor-toolbar/text-editor-toolbar.component';
import { QuillModule } from 'ngx-quill';
import { LetterAvatarComponent } from './letter-avatar/letter-avatar.component';
import { TextViewEditorComponent } from './text-view-editor/text-view-editor.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { DotFlashingComponent } from './dot-flashing/dot-flashing.component';
import { TabsComponent } from './tabs/tabs.component';
import { AddButtonComponent } from './button/add-button.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { DatepickerHeaderComponent } from './datepicker/datepicker-header/datepicker-header.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { SegmentedTabsComponent } from './tabs/segmented-tabs.component';
import { CollapsibleSectionComponent } from './collapsible-section/collapsible-section.component';
import { CheckboxFormComponent } from './checkbox/checkbox-form.component';
import { DropdownMultipleFormComponent } from './dropdown-multiple/dropdown-multiple-form.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { PulsingDotComponent } from './pulsing-dot/pulsing-dot.component';
import { SelectComponent } from './select/select.component';
import { BrowserModule } from '@angular/platform-browser';
import { SelectFieldComponent } from './select/select-field/select-field.component';
import { SelectOptionsComponent } from './select/select-options/select-options.component';
import { StatusSelectComponent } from './status-select/status-select.component';
import { DirectiveModule } from '../directives/directive.module';
import { SectionDotFlashingComponent } from './dot-flashing/section-dot-flashing.component';
import { NotificationComponent } from './notification/notification.component';
import { KeyboardShortcutsDirective } from '../directives/keyboard-shortcuts.directive';
import { CarouselDialogComponent } from './carousel-dialog/carousel-dialog.component';
import { CarouselSlideComponent } from './carousel-dialog/carousel-slide.component';
import { TagComponent } from './tag/tag.component';
import { CollapsibleIconComponent } from './collapsible-icon/collapsible-icon.component';
import { SelectSimpleComponent } from './select-simple/select-simple.component';
import { SelectTreeComponent } from './select/select-tree.component';
import { SelectTreeOptionsComponent } from './select/select-tree-options/select-tree-options.component';

@NgModule({
  declarations: [
    TextboxComponent,
    TextboxFormComponent,
    ButtonComponent,
    DialogComponent,
    SideDialogComponent,
    ConfirmDialogComponent,
    UploadPictureComponent,
    RadioButtonComponent,
    DropdownMultipleFormComponent,
    OnClickOutsideComponent,
    SpinnerComponent,
    ContextMenuComponent,
    TextareaComponent,
    CheckboxComponent,
    CheckboxFormComponent,
    TextEditorToolbarComponent,
    TextEditorComponent,
    LetterAvatarComponent,
    TextViewEditorComponent,
    AlertDialogComponent,
    DotFlashingComponent,
    TabsComponent,
    AddButtonComponent,
    DatepickerComponent,
    DatepickerHeaderComponent,
    UploadFileComponent,
    ToggleSwitchComponent,
    SegmentedTabsComponent,
    CollapsibleSectionComponent,
    TooltipComponent,
    PulsingDotComponent,
    SelectComponent,
    SelectSimpleComponent,
    SelectTreeComponent,
    SelectTreeOptionsComponent,
    SelectFieldComponent,
    SelectOptionsComponent,
    StatusSelectComponent,
    SectionDotFlashingComponent,
    NotificationComponent,
    CarouselDialogComponent,
    CarouselSlideComponent,
    TagComponent,
    CollapsibleIconComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgxDropzoneModule,
    QuillModule,
    IconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DirectiveModule,
    KeyboardShortcutsDirective,
  ],
  exports: [
    IconModule,
    TextboxComponent,
    TextboxFormComponent,
    ButtonComponent,
    DialogComponent,
    SideDialogComponent,
    ConfirmDialogComponent,
    UploadPictureComponent,
    RadioButtonComponent,
    DropdownMultipleFormComponent,
    OnClickOutsideComponent,
    SpinnerComponent,
    ContextMenuComponent,
    DragDropModule,
    TextareaComponent,
    CheckboxComponent,
    CheckboxFormComponent,
    TextEditorComponent,
    LetterAvatarComponent,
    TextViewEditorComponent,
    AlertDialogComponent,
    DotFlashingComponent,
    TabsComponent,
    AddButtonComponent,
    DatepickerComponent,
    UploadFileComponent,
    ToggleSwitchComponent,
    SegmentedTabsComponent,
    CollapsibleSectionComponent,
    TooltipComponent,
    PulsingDotComponent,
    SelectComponent,
    SelectSimpleComponent,
    SelectTreeComponent,
    SelectTreeOptionsComponent,
    SelectFieldComponent,
    SelectOptionsComponent,
    StatusSelectComponent,
    SectionDotFlashingComponent,
    NotificationComponent,
    CarouselDialogComponent,
    CarouselSlideComponent,
    TagComponent,
    CollapsibleIconComponent,
  ],
})
export class UiModule {}
