<esg-confirm-dialog
  *ngIf="removeContentId"
  [message]="removeContentMessage"
  (onCancel)="removeContentId = undefined"
  (onSubmit)="handleOnDelete()"></esg-confirm-dialog>
<div *ngIf="!readMode" class="content-toolbar">
  <esg-content-menu
    class="content-menu"
    [class.sticky]="stickyMenu"
    [style.top.px]="stickyTopMargin"
    [reordering]="reordering"
    [reorderHidden]="isOrganizeHidden"
    (onAddContent)="onAddContent.emit($event)"
    (onReorder)="handleReorder()"></esg-content-menu>
</div>
<div class="content" [class.sticky-toolbar]="!readMode && stickyMenu">
  <ng-container *ngIf="loading">
    <esg-dot-flashing class="loader" label="Loading content"></esg-dot-flashing>
  </ng-container>
  <ng-container *ngIf="!loading">
    <esg-content-list
      class="content-list"
      cdkDropList
      (cdkDropListDropped)="handleOnContentDrop($event)"
      (onDelete)="removeContentId = $event"
      [readMode]="readMode"
      [reorderMode]="reordering"
      [onChange]="onChange"
      [contentItems]="contentItems"></esg-content-list>
  </ng-container>
</div>
