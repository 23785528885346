import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  NewIndicatorTopicItemVm,
  ReportMainLevelVm,
  ReportTopicVm,
  TextDisclosureTopicItemVm,
  TimePeriod,
  TopicItemType,
  TopicItemVm,
} from 'src/api-client/report-api.generated';
import { ReportAppApiService } from '../api-client/report-api/app-api-service';
import { ReportApiService } from '../api-client/report-api/report-api-service';
import { SubstrateDataPreviewUi } from '../shared/components/substrate-data-preview/substrate-data-preview.component';
import { ITabItem } from '../shared/ui/tabs/tabs.component';
import { Breakpoint } from '../static-data/breakpoints';

export interface MainLevel {
  id?: string;
  name?: string | undefined;
  topics?: Topic[] | undefined;
  active: boolean;
}

interface Topic {
  id?: string;
  mainLevelId?: string;
  name?: string | undefined;
  topicItems?: TopicItem[] | undefined;
  active: boolean;
}

interface ITopicItem {
  id?: string;
  type?: TopicItemType;
  name?: string;
  referenceStandards?: string;
  description?: string;
  dataValues?: IKeyfigures[];
  substrateData?: SubstrateDataPreviewUi;
  components?: Component[];
}

export interface TopicItem extends ITopicItem {
  audit?: boolean;
  active: boolean;
}

interface IKeyfigures {
  currentValue: IKeyFigureValue;
  metadata: IKeyFiguresMetaData;
  previousValue: IKeyFigureValue;
  targetValue: IKeyFigureValue;
}

interface IKeyFiguresMetaData {
  label?: string;
  type?: string;
  unit?: string;
  isVisibleInReport: boolean;
}

interface IKeyFigureValue {
  type?: string;
  value?: string | number;
  unitDetails?: string;
}

interface Component {
  id?: string;
  reportId?: string;
  title?: string | undefined;
  showTitleInReport?: boolean | undefined;
  content?: any | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class ReportPreviewStateService {
  showShareButton: boolean = false;
  showShareDialog: boolean = false;
  mainLevels?: MainLevel[] = [];
  public readonly mainLevelBehavior = new BehaviorSubject<(MainLevel | undefined)[]>([]);
  mainLevelsObservable$ = this.mainLevelBehavior.asObservable();
  reportId: string | undefined = '';
  reportName: string | undefined = '';
  timePeriod?: TimePeriod;
  organizationName: string | undefined = '';
  organizationLogoSquareUrl: string | undefined = '';
  coverPhoto: string | undefined = '';
  brandColor: string | undefined = '#4ad1da';
  tableHeadColor: string | undefined = '#bedde0';

  mainLevelId: string = '';

  mainTopicTabs: ITabItem[] = [{ value: 'keyFigures', link: ['key-figures'], label: 'Key Figures' }];
  isMenuOpen: boolean = false;
  stickyTabs: boolean = false;
  showNavbarDetails: boolean = false;

  readonly breakpointEnum = Breakpoint;
  currentBreakpoint: Breakpoint = Breakpoint.WideDesktop;

  get isDesktop() {
    return (
      this.currentBreakpoint === this.breakpointEnum.WideDesktop ||
      this.currentBreakpoint === this.breakpointEnum.NarrowDesktop
    );
  }
  get isWideDesktop() {
    return this.currentBreakpoint === this.breakpointEnum.WideDesktop;
  }
  get isNarrowDesktop() {
    return this.currentBreakpoint === this.breakpointEnum.NarrowDesktop;
  }
  get isTablet() {
    return this.currentBreakpoint === this.breakpointEnum.Tablet;
  }
  get isMobile() {
    return this.currentBreakpoint === this.breakpointEnum.Mobile;
  }
  get isNarrow() {
    return (
      this.currentBreakpoint === this.breakpointEnum.Tablet ||
      this.currentBreakpoint === this.breakpointEnum.NarrowDesktop
    );
  }

  constructor(private reportApiService: ReportApiService, private appApiService: ReportAppApiService) {}

  async initHTMLReport(reportId: string, token?: string) {
    if (!token) {
      this.showShareButton = true;
    }
    if (!reportId) return;
    const completeReportData = token
      ? await this.reportApiService.getCompleteTokenAuthReportById(reportId, token)
      : await this.reportApiService.getCompleteReportById(reportId);
    this.reportId = reportId;
    this.reportName = completeReportData.name;
    this.timePeriod = completeReportData.timePeriod;
    this.organizationName = completeReportData.organizationName;
    if (completeReportData.brandColor) {
      this.brandColor = completeReportData.brandColor;
    }
    if (completeReportData.tableHeadColor) {
      this.tableHeadColor = completeReportData.tableHeadColor;
    }
    if (completeReportData.organizationLogoSquare?.blobName) {
      this.organizationLogoSquareUrl = this.appApiService.getBlobUrl(
        completeReportData.organizationLogoSquare?.blobName
      );
    }

    if (completeReportData.coverPhoto?.blobName) {
      this.coverPhoto = this.appApiService.getBlobUrl(completeReportData.coverPhoto?.blobName);
    }

    this.mainLevels =
      completeReportData.mainLevels?.map(m => {
        this.mainTopicTabs.push({ value: m.name, label: m.name, link: [m.id] });
        return this.mapToMainLevel(m);
      }) || [];
    this.mainLevelBehavior.next(this.mainLevels);
  }

  mapToMainLevel(mainLevelData: ReportMainLevelVm) {
    const mappedTopics = mainLevelData.topics?.map(t => this.mapToTopic(t));
    const mapped: MainLevel = {
      id: mainLevelData.id,
      name: mainLevelData.name,
      topics: mappedTopics,
      active: false,
    };
    return mapped;
  }

  mapToTopic(topic: ReportTopicVm): Topic {
    const mappedTopicItems = topic.topicItems?.map(i => this.mapToTopicItem(i));
    const mapped: Topic = {
      id: topic.id,
      mainLevelId: topic.mainLevelId,
      name: topic.name,
      topicItems: mappedTopicItems,
      active: false,
    };
    return mapped;
  }

  mapToTopicItem(topicItem: TopicItemVm): TopicItem {
    if (topicItem instanceof NewIndicatorTopicItemVm) {
      const references = Array.from(
        new Set(
          topicItem.dataValues.flatMap(value => value.referenceStandards.replace(/(\r\n|\n|\r)/gm, '\n').split('\n'))
        )
      ).join('\n');
      return {
        id: topicItem.id,
        name: topicItem.name,
        type: topicItem.type,
        referenceStandards: references,
        audit: false,
        dataValues: topicItem.dataValues.map(value => ({
          targetValue: value.targetValue as IKeyFigureValue,
          currentValue: value.currentValue as IKeyFigureValue,
          metadata: value.metadata,
          previousValue: value.previousValue as IKeyFigureValue,
        })),
        components: topicItem.components,
        active: false,
      };
    } else if (topicItem instanceof TextDisclosureTopicItemVm) {
      return {
        id: topicItem.id,
        name: topicItem.name,
        type: topicItem.type,
        description: topicItem.description,
        referenceStandards: topicItem.referenceStandards,
        audit: false,
        components: topicItem.components,
        active: false,
      };
    } else {
      return {
        id: topicItem.id,
        name: topicItem.name,
        audit: false,
        active: false,
      };
    }
  }

  setBreakPoint(breakpoint?: Breakpoint) {
    this.currentBreakpoint = breakpoint || Breakpoint.WideDesktop;
  }

  onToggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  handleTabsObservation(tabEntry: IntersectionObserverEntry) {
    this.stickyTabs = tabEntry.boundingClientRect.top < 0;
    const ratio = tabEntry.intersectionRatio;
    this.showNavbarDetails = ratio < 1;
  }

  handleShare() {
    this.showShareDialog = true;
  }

  handleShareDialogClose() {
    this.showShareDialog = false;
  }
}
