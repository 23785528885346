import {
  CalculatedDataClient,
  CalculateIndicatorYearlyCalculatedDataCommandParams,
  CalculateIndicatorYearlyCalculatedDataCommandResponse,
  GetCalcDataByIndicatorQueryResponse,
  GetCalcDataSubstrateDataQueryResponse,
  TimePeriod,
  TimePeriodType,
} from '../../../api-client/report-api.generated';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tmpdir } from 'os';
import { lastValueFrom } from 'rxjs';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';

@Injectable({
  providedIn: 'root',
})
export class CalculatedDataApiService {
  client: CalculatedDataClient;

  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new CalculatedDataClient(httpClient, appSettings.ReportApiBaseUrl);
  }

  async createIndicatorData(
    indicatorId: string,
    year: number
  ): Promise<CalculateIndicatorYearlyCalculatedDataCommandResponse> {
    const observable$ = this.client.calculateIndicatorYearlyCalculatedData(
      new CalculateIndicatorYearlyCalculatedDataCommandParams({ indicatorId, year })
    );
    return await lastValueFrom(observable$);
  }

  async getByIndicatorIdCalculatedData(
    includeNestedSubOrganizations: boolean,
    indicatorId: string,
    periodType: TimePeriodType,
    subOrganizationId?: string,
    yearFrom?: number,
    yearTo?: number
  ): Promise<GetCalcDataByIndicatorQueryResponse> {
    const observable$ = this.client.getCalcDataByIndicator(
      indicatorId,
      yearFrom,
      yearTo,
      subOrganizationId,
      includeNestedSubOrganizations,
      periodType,
      undefined
    );
    return await lastValueFrom(observable$);
  }

  async getCalculatedDataSubstrateData(
    indicatorId: string,
    subOrganizationId: string | undefined,
    includeNestedSubOrganizations: boolean,
    timePeriodType: TimePeriodType,
    year: number,
    period: number | undefined
  ): Promise<GetCalcDataSubstrateDataQueryResponse> {
    const observable$ = this.client.getCalcDataSubstrateData(
      indicatorId,
      subOrganizationId,
      includeNestedSubOrganizations,
      timePeriodType,
      year,
      period
    );
    return await lastValueFrom(observable$);
  }
}
