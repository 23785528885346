import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  CreateComponentCommandParams,
  UpdateComponentCommandParams,
  MoveComponentCommandParams,
  DeleteComponentCommandParams,
  ComponentType,
} from 'src/api-client/report-api.generated';
import { ReportAppApiService } from 'src/app/api-client/report-api/app-api-service';
import { TopicItemApiService } from 'src/app/api-client/report-api/topic-item-api-service';
import { IUploadedPhoto } from 'src/app/shared/ui/upload-picture/upload-picture.component';
import { ContentDataTypes, ContentStatesTypes, ContentTypes } from 'src/app/content/content-configuration';
import { DynamicContent, DynamicComponentFactory } from 'src/app/content/dynamic-content';
import { IPictureContentData } from 'src/app/content/content-item/picture/picture.component';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { IMovedContent } from 'src/app/content/dynamic-content-data';
import { ChatAIApiService } from 'src/app/api-client/report-api/chat-ai-service';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class ComponentsStateService {
  constructor(
    private topicItemApiService: TopicItemApiService,
    private appApiService: ReportAppApiService,
    private domSanitizer: DomSanitizer,
    private chatAIService: ChatAIApiService
  ) {}

  reportId?: string;
  topicItemId?: string;
  componentsList: DynamicContent[] = [];

  defaultCompTextTitle = 'Untitled paragraph';
  defaultCompImageTitle = 'Untitled image';
  defaultCompImportFromExcelTitle = 'Untitled import from excel';

  loading: boolean = false;
  fade: boolean = false;

  getTopicItemComponents(reportId: string, topicItemId: string) {
    this.reportId = reportId;
    this.topicItemId = topicItemId;
    this.loading = true;
    this.topicItemApiService.getTopicItemComponents(topicItemId, reportId).subscribe(result => {
      this.componentsList = result.map(c => {
        const dynamicComponent = DynamicComponentFactory({
          uploadPhoto: this.handleUploadPhoto,
          getUploadedPhoto: this.getUploadPhoto,
          generateText: this.handleGetBotAnswer,
        })[c.type];
        return dynamicComponent(c.id, c.content);
      });
      this.loading = false;
    });
  }

  handleOnAddComponent(type: ContentTypes) {
    switch (type) {
      case ContentTypes.Text:
        this.addTextComponent();
        break;
      case ContentTypes.Picture:
        this.addImageComponent();
        break;
      case ContentTypes.ImportFromExcel:
        this.addImportFromExcelComponent();
        break;
      default:
        break;
    }
  }

  addTextComponent() {
    this.topicItemApiService
      .addComponent(
        new CreateComponentCommandParams({
          topicItemId: this.topicItemId || '',
          reportId: this.reportId || '',
          type: ComponentType.Text,
          title: '',
          showTitleInReport: false,
        })
      )
      .subscribe(result => {
        this.componentsList = [
          ...this.componentsList,
          new DynamicContent(
            result.result?.id || '',
            ContentTypes.Text,
            {
              generateText: this.handleGetBotAnswer,
            },
            {},
            undefined
          ),
        ];
      });
  }

  addImageComponent() {
    this.topicItemApiService
      .addComponent(
        new CreateComponentCommandParams({
          topicItemId: this.topicItemId || '',
          reportId: this.reportId || '',
          type: ComponentType.Picture,
          title: '',
          showTitleInReport: false,
        })
      )
      .subscribe(result => {
        this.componentsList = [
          ...this.componentsList,
          new DynamicContent(
            result.result?.id || '',
            ContentTypes.Picture,
            {
              uploadPhoto: this.handleUploadPhoto(result.result?.id || ''),
            },
            {},
            undefined
          ),
        ];
      });
  }

  addImportFromExcelComponent() {
    this.topicItemApiService
      .addComponent(
        new CreateComponentCommandParams({
          topicItemId: this.topicItemId || '',
          reportId: this.reportId || '',
          type: ComponentType.ImportFromExcel,
          title: '',
          showTitleInReport: false,
        })
      )
      .subscribe(result => {
        this.componentsList = [
          ...this.componentsList,
          new DynamicContent(result.result?.id || '', ContentTypes.ImportFromExcel, {}, {}, undefined),
        ];
      });
  }

  moveComponent(movedContent: IMovedContent) {
    const newList = [...this.componentsList];
    const prevList = [...this.componentsList];
    moveItemInArray(newList, movedContent.prevIndex, movedContent.newIndex);
    this.componentsList = [...newList];
    this.topicItemApiService
      .moveComponent(
        new MoveComponentCommandParams({
          topicItemId: this.topicItemId || '',
          componentId: movedContent.contentId,
          prevIndex: movedContent.prevIndex,
          newIndex: movedContent.newIndex,
        })
      )
      .subscribe(
        result => {},
        error => {
          this.componentsList = [...prevList];
        }
      );
  }

  deleteComponent(contentId: string) {
    this.topicItemApiService
      .removeComponent(
        new DeleteComponentCommandParams({ topicItemId: this.topicItemId || '', componentId: contentId })
      )
      .subscribe(result => {
        if (result.success) {
          const newList = [...this.componentsList];
          newList.splice(
            this.componentsList.findIndex(comp => comp.contentId === contentId),
            1
          );
          this.componentsList = [...newList];
        }
      });
  }

  onDataChangeComponent = (contentId: string, states: ContentStatesTypes, data?: ContentDataTypes) => {
    const component = this.componentsList.find(c => c.contentId === contentId);

    if (!component) {
      throw Error(`Component with id: ${contentId} doesn't exists`);
    }

    var updatedComponent: DynamicContent = {
      ...component,
      states: states,
      data,
    };

    this.componentsList = this.componentsList.map(c => (c.contentId !== component.contentId ? c : updatedComponent));

    this.topicItemApiService
      .updateComponent(
        new UpdateComponentCommandParams({
          componentId: component.contentId,
          topicItemId: this.topicItemId || '',
          title: '',
          showTitleInReport: false,
          content: data ? JSON.stringify(data) : '',
        })
      )
      .subscribe(result => {
        console.log('updated successfully!');
      });
  };

  async uploadPhoto(file: File) {
    const photo$ = this.appApiService.uploadImage({
      data: file,
      fileName: file.name,
    });
    return await lastValueFrom(photo$);
  }

  getUploadPhoto = (blobName: string): IUploadedPhoto => {
    var blobUrl = this.appApiService.getBlobUrl(blobName);
    var safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(blobUrl);
    return {
      url: safeUrl,
      fileName: blobName,
    };
  };

  handleUploadPhoto = (componentId: string) => async (file?: File) => {
    const component = this.componentsList.find(c => c.contentId === componentId);

    if (!component) {
      throw Error(`Comonent with id: ${componentId} doesn't exists`);
    }

    if (file) {
      const result = await this.uploadPhoto(file);
      const uploadedPhoto = this.getUploadPhoto(result.blobName || '');

      const updatedContent: IPictureContentData = {
        altText: '',
        blobName: result.blobName || '',
      };

      this.onDataChangeComponent(
        componentId,
        {
          ...component?.states,
          uploadedPhoto: uploadedPhoto,
        },
        updatedContent
      );
    } else {
      this.onDataChangeComponent(
        componentId,
        {
          ...component?.states,
          uploadedPhoto: undefined,
        },
        undefined
      );
    }
  };

  handleGetBotAnswer = async (question: string): Promise<string> => {
    return (await this.chatAIService.getBotAnswer(question)).answer;
  };

  onReorder(reordering: boolean) {
    this.fade = reordering;
  }
}
