import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportDetailsComponent } from './report-details.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { ContentModule } from '../../content/content.module';
import { ReportStructureComponent } from './report-structure/report-structure.component';
import { MainLevelItemComponent } from './report-structure/main-level-item/main-level-item.component';
import { TopicItemComponent } from './report-structure/topic-item/topic-item.component';
import { TopicDetailsComponent } from './topic-details/topic-details.component';
import { TopicItemListComponent } from './topic-details/topic-item-list/topic-item-list.component';
import { TopicItemDetailsComponent } from './topic-details/topic-item-details/topic-item-details.component';
import { IndicatorValuesComponent } from './topic-details/topic-item-details/indicator-values/indicator-values.component';
import { ReportMenuItemComponent } from './report-structure/report-menu-item/report-menu-item.component';
import { AppLayoutComponent } from 'src/app/app-layout/app-layout.component';
import { ReportPageComponent } from '../report/report.component';
import { ReportPreviewModule } from 'src/app/report-preview/report-preview.module';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { MsalGuard } from '@azure/msal-angular';
import { SubstrateDataComponent } from './topic-details/topic-item-details/substrate-data/substrate-data.component';
import { DirectiveModule } from 'src/app/shared/directives/directive.module';
import { IndicatorInputComponent } from './topic-details/topic-item-details/indicator-input/indicator-input.component';
import { NoTopicSelectedComponent } from './no-topic-selected/no-topic-selected.component';
import { ReportModule } from '../report/report.module';
import { KeyboardShortcutsDirective } from 'src/app/shared/directives/keyboard-shortcuts.directive';
import { FormatUnitWithUnicodePipe } from 'src/app/shared/pipes/format-unit-with-unicode.pipe';
import { NewIndicatorFormComponent } from './topic-details/new-indicator-form/new-indicator-form.component';
import { TextDisclosureFormComponent } from './topic-details/text-disclosure-form/text-disclosure-form.component';

@NgModule({
  declarations: [
    ReportDetailsComponent,
    ReportStructureComponent,
    MainLevelItemComponent,
    TopicItemComponent,
    TopicDetailsComponent,
    TopicItemListComponent,
    TopicItemDetailsComponent,
    IndicatorValuesComponent,
    ReportMenuItemComponent,
    SubstrateDataComponent,
    IndicatorInputComponent,
    NoTopicSelectedComponent,
    NewIndicatorFormComponent,
    TextDisclosureFormComponent,
  ],
  imports: [
    CommonModule,
    UiModule,
    ReactiveFormsModule,
    ContentModule,
    QuillModule,
    ReportPreviewModule,
    SharedComponentsModule,
    ReportModule,
    DirectiveModule,
    KeyboardShortcutsDirective,
    FormatUnitWithUnicodePipe,
    RouterModule.forChild([
      {
        path: 'report',
        component: AppLayoutComponent,
        children: [
          { path: '', component: ReportPageComponent },

          {
            path: `:id`,
            component: ReportDetailsComponent,
            children: [
              {
                path: '',
                component: NoTopicSelectedComponent,
              },
              {
                path: ':topicid',
                component: TopicDetailsComponent,
              },
            ],
          },
        ],
        canActivate: [MsalGuard],
      },
    ]),
  ],
})
export class ReportDetailsModule {}
